import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { increaseCount } from "./userActionHistory";
import addHistory from "./historyHelper";

export async function createNewTask(data) {

  console.log('new task with data '+ data)
  const taskUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/`;
  const taskData = {
    _id: uuidv4(),
    completed: false,
    completable: true,
    date: data.date,
    __v: 0,
    description: data.description,
    title: data.title,
    localNumber: data.localNumber || "",
    union: data.union || "",
    clientType: "Custom Task",
    assignedRep: data.assignedRep, // Ensure this matches the field in your data object
    propertyToUpdate: data.taskType,
    link: data.link || "",
  };

  try {
    // Use axios to send a POST request
    const response = await axios.post(taskUrl, taskData);
    console.log("Task created:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating task:", error);
    throw error; // Rethrow the error for handling by the caller
  }
}

export function deleteTask({ id, refreshTasks }) {
  const taskDeleteUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${id}`;

  axios
    .delete(taskDeleteUrl)
    .then((response) => {
      // Assuming a successful response has a status code of 200
      if (response.status === 200) {
        console.log(`Task with ID ${id} has been deleted.`);
        window.location.reload(); // Reload the page after successful deletion
      } else {
        console.error(
          `Failed to delete task with ID ${id}. Status: ${response.status}`,
        );
      }
    })
    .catch((error) => {
      // Handle errors
      console.error(`Error deleting task with ID ${id}: ${error.message}`);
    });
  refreshTasks(); // Refresh tasks after deletion (optional, depending on your use case)
}

export async function NoContactBeforeDemo({ task, refreshTasks }) {
  const taskAPI = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${task._id}`;
  try {
    const response = await axios.put(taskAPI, {
      ...task,
      title: `Recontact to confirm appointment with ${task.union} ${task.localNumber}`,
    });

    if (response && response.status === 200) {
      await addHistory({
        id: task.link,
        note: "Could not reach client day before Demo",
      });
      await refreshTasks(task.union, task.localNumber);
    } else {
      console.error(
        `Failed to initiate no contact. Status: ${response ? response.status : "No response"}`,
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export async function changeTaskUnionLocal({ unionData, id }) {
  try {
    const response = await axios.get(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
    );

    if (response.status === 200) {
      const tasks = response.data;

      // Filter tasks based on the 'link' property
      const matchingTasks = tasks.filter((task) => task.link === id);

      // Log the number of matching tasks
      console.log(
        `Found ${matchingTasks.length} tasks with the same link as ${id}`,
      );

      // Update 'union' and 'local' properties for each matching task
      const updateTasksPromises = matchingTasks.map((task) => {
        const updatedTask = {
          ...task,
          union: unionData.union,
          localNumber: unionData.localNumber,
          timeZone: unionData.timeZone,
        };

        // Make a PUT request to update the task
        return axios.put(
          `https://unionapi.netlify.app/.netlify/functions/api/tasks/${task._id}`,
          updatedTask,
        );
      });

      // Wait for all update requests to complete
      await Promise.all(updateTasksPromises);

      console.log("Tasks updated successfully.");
    } else {
      console.error("Failed to fetch tasks:", response.statusText);
    }
  } catch (error) {
    console.error("Error while fetching or updating tasks:", error.message);
  }
}

export async function completeTask({
  id,
  reloadFunction,
  property,
  rep,
  task,
}) {
  
  try {
    increaseCount(rep, property);
    // First, fetch all tasks to check the date and completion status
    const getUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${task.union}&localNumber=${task.localNumber}`;
    const getResponse = await axios.get(getUrl);

    // Filter tasks by those that are not completed
    const incompleteTaskDates = getResponse.data
      .filter((t) => !t.completed)
      .map((t) => t.date.split("T")[0]);

    // Current task's date (splitting to ignore the time part)
    const currentTaskDate = task.date.split("T")[0];

    // Determine if the current task has the earliest date among all incomplete tasks
    const isEarliestOrTied = incompleteTaskDates.every(
      (otherDate) => currentTaskDate <= otherDate,
    );

    if (!isEarliestOrTied) {
      console.error(
        "Cannot complete task: Task date is not the earliest among incomplete tasks.",
      );
      return false;
    }

    // If the current task's date is the earliest, proceed to mark it as completed
    const data = {
      completed: true,
    };
    const taskUpdateUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${id}`;
    const response = await axios.put(taskUpdateUrl, data);
    
    if (response.status === 200) {
      
      console.log("Task completed successfully");

      if (task.title.includes("Send wait letter")) {
        await addHistory({
          id: task.link,
          note: "Sent wait letter",
        });
      } else if (task.title.includes("Interim phone call")) {
        await addHistory({
          id: task.link,
          note: "Interim phone call",
        });
      }

      reloadFunction && reloadFunction();
      
      return true;
    } else {
      console.error("Failed to complete task:", response);
      return false;
    }
  } catch (error) {
    console.error("Error completing task:", error);
    return false;
  }
}




export async function createElectionTask(unionData) {
  try {
    // Fetch tasks from the API
    const response = await axios.get(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionData.union}&localNumber=${unionData.localNumber}`
    );


    if (response.status === 200) {
      const tasks = response.data;
      const preElectionCheckUpTask = tasks.find(task => task.title.includes('Pre-Election Check Up'));


      if (unionData.electionDate) {
        // If there is a pre-election check-up task, update it
        if (preElectionCheckUpTask) {
          const foundTask_id = preElectionCheckUpTask._id;
          const today = new Date();
          const ninetyDaysBeforeElection = new Date(unionData.electionDate);
          ninetyDaysBeforeElection.setDate(ninetyDaysBeforeElection.getDate() - 90);
          console.log(`90 days before`, ninetyDaysBeforeElection)
          const date = today > ninetyDaysBeforeElection ? today.toISOString() : ninetyDaysBeforeElection.toISOString();


          // Update the task using axios.put
          await axios.put(`https://unionapi.netlify.app/.netlify/functions/api/tasks/${foundTask_id}`, {
            ...preElectionCheckUpTask,
            date,
            transferable:true, // Makes it so the tasks will send to the call calendar
            completed: false // Make sure completed is set to false
          });
        } else {
          // If no pre-election check-up task found, create a new one
          const today = new Date();
          const ninetyDaysBeforeElection = new Date(unionData.electionDate);
          ninetyDaysBeforeElection.setDate(ninetyDaysBeforeElection.getDate() - 90);
          console.log(`90 days before`, ninetyDaysBeforeElection)
          const date = today > ninetyDaysBeforeElection ? today.toISOString() : ninetyDaysBeforeElection.toISOString();


          const electionCheckUpTask = {
            _id: uuidv4(),
            completed: false,
            date,
            __v: 0,
            localNumber: unionData.localNumber,
            assignedRep: unionData.assignedRep,
            link: unionData._id,
            contactEmail:
              unionData.primaryContact && unionData.primaryContact === 1
                ? unionData.contactEmail || unionData.alternateContactEmail || unionData.email
                : unionData.email || "",
            description: `Ask about their election in the upcoming months`,
            title: "Pre-Election Check Up",
            union: unionData.union,
            clientType: unionData.clientType,
            completable: true,
            propertyToUpdate: "callsMade",
            transferable: true
          };


          // Create the task using axios.post
          await axios.post(`https://unionapi.netlify.app/.netlify/functions/api/tasks`, electionCheckUpTask);
        }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

