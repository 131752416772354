import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { returnFirstName } from "../helpers/localStorage";
import { createNewTask } from "../helpers/taskActions";
import "./Page.css";
import { Link } from "react-router-dom";
import { FaPrint, FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa";
import Navbar from "../components/navigation/Navbar";
import TaskComponent from "../components/TaskComponent";
import PrintTaskComponent from "../components/PrintTaskComponent";
import { formatSpelledDate } from "../helpers/formatDate";
import AnalyticsContent2 from "../components/content/AnalyticsContent2";
//import AnalyticsContent from "../components/content/AnalyticsContent";

export default function Dashboard({ salesReps }) {
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [modalTasks, setModalTasks] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [showTasks, setShowTasks] = useState(true);
  const [tasks, setTasks] = useState([]);
  const loggedInUser = localStorage.getItem("loggedInUser");
  const isAdmin = localStorage.getItem("isAdmin");
  const [unionsData, setUnionsData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState(
    isAdmin ? "all" : returnFirstName(),
  ); // Default to logged-in user
  const [descriptionStates, setDescriptionStates] = useState({});
  const [currentWeekStart, setCurrentWeekStart] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(
      currentDate.getDate() - ((currentDate.getDay() + 6) % 7),
    );
    return currentDate; // Set to this week's Monday
  });

  const [clients, setClients] = useState([]);

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    date: new Date().toISOString(),
    localNumber: "",
    union: "",
    assignedRep: "",
  });

  // Function to update the newTask state
  const handleNewTaskChange = (e) => {
    const { name, value } = e.target;

    // Check if the change is for the date field
    if (name === "date") {
      let dateValue = new Date(value); // Convert the input string back to a Date object
      dateValue.setDate(dateValue.getDate() + 1); // Add one day

      // Update the state with the modified date
      setNewTask((prevNewTask) => ({
        ...prevNewTask,
        [name]: dateValue.toISOString(), // Convert the date back to an ISO string
      }));
    } else {
      // Handle changes for other fields normally
      setNewTask((prevNewTask) => ({
        ...prevNewTask,
        [name]: value,
      }));
    }
  };

  const handleClientChange = (e) => {
    const selectedClientId = e.target.value;
    const selectedClient = clients.find((client) => client._id === selectedClientId);
  
    if (selectedClient) {
      // Update the newTask state with selected client's details
      setNewTask((prevNewTask) => ({
        ...prevNewTask,
        link: selectedClientId, // Set the link to the client's _id
        union: selectedClient.union, // Set the union
        localNumber: selectedClient.localNumber, // Set the local number
      }));
    } else {
      // Reset values if no client is selected
      setNewTask((prevNewTask) => ({
        ...prevNewTask,
        link: "",
        union: "",
        localNumber: "",
      }));
    }
  };
  
  

  // Function to create a new task
  const createTask = async () => {
    console.log("newTask.taskType is", newTask.taskType);
  
    try {
      // Prepare the task data
      const taskToCreate = {
        ...newTask,
        assignedRep: !isAdmin ? selectedSalesRep : newTask.assignedRep,
        link: newTask.link, // link comes from the selected client's _id
        date: newTask.date,
        union: newTask.union || " ", // union from selected client
        localNumber: newTask.localNumber || " ", // localNumber from selected client
        propertyToUpdate: newTask.taskType,
      };
  
      // Send the task data to the API
      await createNewTask(taskToCreate);
      console.log("Task successfully created");
  
      // Reset task state and close modal after success
      setNewTask({
        title: "",
        description: "",
        date: "",
        localNumber: "",
        union: "",
        assignedRep: "",
        link: "", // Reset the link field as well
      });
      closeNewTaskModal();
      refreshTasks(); // Refresh the task list to include the new task
    } catch (error) {
      console.error("Failed to create task:", error);
      // Optionally, display an error message to the user
    }
  };
  
  // Function to navigate to the previous week
  const goToPreviousWeek = () => {
    setCurrentWeekStart((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    });
  };

  // Function to navigate to the next week
  const goToNextWeek = () => {
    setCurrentWeekStart((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 7);
      return newDate;
    });
  };

  // Function to toggle full description for a task
  const toggleDescription = (taskId) => {
    setDescriptionStates((prevStates) => ({
      ...prevStates,
      [taskId]: !prevStates[taskId],
    }));
  };

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // Calculate the start date for the week (Monday)
  const currentDate = new Date(); // Get the current date
  currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7)); // Set to this week's Monday

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  // Create an array of objects for each day of the week
  const overdueTasks = tasks.filter((task) => {
    const taskDate = new Date(task.date);

    // Check if the task date is older than the current week's Monday
    return taskDate < currentDate && !isSameDay(taskDate, currentDate);
  });

  const days = [
    {
      day: "Overdue",
      date: "Past Tasks", // You can set the date to 'N/A' or any suitable value for the 'Overdue' day
      tasks: overdueTasks, // Add the filtered tasks to the 'Overdue' day
    },
    ...daysOfWeek.map((day, index) => {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + index); // Add days to the current date

      // Format the date as MM/DD/YYYY
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

      return {
        day,
        date: formattedDate,
      };
    }),
  ];

  // Check if currentWeekStart is the same as this week's Monday
  const today = new Date();
  today.setDate(today.getDate() - ((today.getDay() + 6) % 7));
  const isThisWeek = currentWeekStart.toDateString() === today.toDateString();

  const getColorClass = (clientType) => {
    switch (clientType) {
      case "Suspect":
        return "skyBlue"; // Light blue
      case "Prospect":
        return "yellow"; // Yellow
      case "Activation":
        return "orange"; // Orange
      case "Proposal":
        return "green"; // Lime green
      default:
        return "red"; // Default color if clientType is not recognized
    }
  };
 // Unified function to fetch unions/clients data
const fetchUnionsAndClients = async () => {
  try {
    const response = await axios.get("https://unionapi.netlify.app/.netlify/functions/api/unions");

    // Sort unions/clients alphabetically by "union" (or any other property)
    const sortedData = response.data.sort((a, b) => a.union.localeCompare(b.union));

    setUnionsData(sortedData); // Assuming unionsData is used elsewhere
    setClients(sortedData); // Reusing sorted data for clients
  } catch (error) {
    console.error("Error fetching unions/clients data:", error);
  }
};

// Fetch tasks and unions/clients data
useEffect(() => {
  const fetchTasksAndData = async () => {
    try {
      // Fetch tasks
      const tasksResponse = await axios.get(
        "https://unionapi.netlify.app/.netlify/functions/api/tasks?completed=false"
      );
      const filteredTasks = tasksResponse.data.filter(
        (task) => task.clientType !== "Client"
      );
      setTasks(filteredTasks);

      // Fetch unions/clients after tasks
      await fetchUnionsAndClients();
    } catch (error) {
      console.error("Error fetching tasks or unions/clients data:", error);
    }
  };

  fetchTasksAndData();
}, []);

  const refreshTasks = () => {
    axios
      .get("https://unionapi.netlify.app/.netlify/functions/api/tasks?completed=false")
      .then((response) => setTasks(response.data))
      .catch((error) => console.error("Error fetching tasks:", error));
  };

  const openPrintModal = (day) => {
    setShowTasks(false);
    let tasksToPrint;

    if (day.day === "Overdue") {
      tasksToPrint = day.tasks;
    } else {
      if (selectedSalesRep === "all") {
        tasksToPrint = tasks.filter((task) => {
          const taskDate = new Date(task.date);
          return isSameDay(taskDate, new Date(day.date));
        });
      } else {
        tasksToPrint = tasks.filter((task) => {
          const taskDate = new Date(task.date);
          return (
            isSameDay(taskDate, new Date(day.date)) &&
            task.assignedRep === selectedSalesRep
          );
        });
      }
    }

    setModalTasks(tasksToPrint);
    setModalTitle(`Tasks for ${day.day} (${day.date})`);
    setIsPrintModalOpen(true);
  };

  const closePrintModal = () => {
    setIsPrintModalOpen(false);
    setShowTasks(true);
  };

  const closeNewTaskModal = () => {
    setIsNewTaskModalOpen(false);
  };
  const openNewTaskModal = () => {
    setIsNewTaskModalOpen(true);
  };

  const getClientTypeCount = (clientType) => {
    return unionsData.filter((union) => {
      const clientTypeMatches =
        clientType === undefined
          ? union.clientType !== undefined
          : union.clientType === clientType;
      
      const salesRepMatches =
        selectedSalesRep === "all" || union.assignedRep === selectedSalesRep;
  
      const isNotReturned = union.clientType !== "Returned";
  
      return clientTypeMatches && salesRepMatches && isNotReturned;
    }).length;
  };
  

  // Calculate the end date of the week
  const weekEndDate = new Date(currentWeekStart);
  weekEndDate.setDate(weekEndDate.getDate() + 6);

  // Format the start and end dates
  const formattedStartDate = formatSpelledDate(currentWeekStart);
  const formattedEndDate = formatSpelledDate(weekEndDate);

  return (
    <div>
      <Navbar loggedInUser={loggedInUser} />
      {isPrintModalOpen && (
        <div className="top-0 left-0 w-full h- flex items-center justify-center z-50 bg-opacity-50 ">
          <div className="bg-white w-full max-w-screen-xl max-h-screen-xl mx-auto rounded-lg p-4 shadow-lg overflow-y-auto  modal-container">
            <div className="modal-title text-lg font-semibold mb-4">
              {modalTitle}
            </div>
            <div className="modal-actions flex justify-between mt-4">
              <button
                onClick={closePrintModal}
                className="px-4 py-2 bg-zinc-500 text-white rounded-md hover:bg-zinc-600"
              >
                Close
              </button>
              <button
                onClick={() => window.print()}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-x-2"
              >
                <FaPrint />
                Print
              </button>
            </div>
            <div className="modal-content">
              {modalTasks.map((task) => (
                <PrintTaskComponent
                  key={task._id}
                  task={task}
                  unionData={unionsData}
                  loggedInUser={loggedInUser}
                  color={getColorClass(task.clientType)}
                  isAdmin={isAdmin}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {/* NEW TASK MODAL */}
{isNewTaskModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="bg-white w-2/3 mx-auto rounded-lg p-4 shadow-lg overflow-hidden">
      <h3 className="text-lg font-semibold mb-4">New Task</h3>
      <p className="font-bold text-md">Task Type</p>
      <select
        name="taskType"
        value={newTask.taskType}
        onChange={handleNewTaskChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
      >
        <option value="">Select Task Type</option>
        <option value="callsMade">Phone Call</option>
        <option value="emailsSent">Email</option>
        <option value="other">Other</option>
      </select>
      <p className="font-bold text-md">Title</p>
      <input
        type="text"
        name="title"
        placeholder="Task Title"
        value={newTask.title}
        onChange={handleNewTaskChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
        
      />
      <p className="font-bold text-md">Description</p>
      <textarea
        name="description"
        placeholder="Task Description"
        value={newTask.description}
        onChange={handleNewTaskChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
        rows="3"
      />
      <div className="grid grid-cols-2 gap-2">
        {/* <div>
          <p className="font-bold text-md">Union (Optional)</p>
          <input
            type="text"
            name="union"
            placeholder="Union"
            value={newTask.union}
            onChange={handleNewTaskChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
          />
        </div>
        <div>
          <p className="font-bold text-md">Local Number (Optional)</p>
          <input
            type="text"
            name="localNumber"
            placeholder="Local Number"
            value={newTask.localNumber}
            onChange={handleNewTaskChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
          />
        </div> */}

         {/* Clients Dropdown */}
         <div>
          <p className="font-bold text-md">Client (Optional)</p>
          <select
            name="client"
            value={newTask.link}
            onChange={(e) => handleClientChange(e)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
          >
            <option value="">Select Client</option>
            {clients.map((client) => (
              <option key={client._id} value={client._id}>
                {client.union} {client.localNumber}
              </option>
            ))}
          </select>
        </div>

        <div>
          <p className="font-bold text-md">Date (Required)</p>
          <input
            type="date"
            name="date"
            value={
              newTask.date instanceof Date
                ? new Date(newTask.date.setDate(newTask.date.getDate() + 1)).toISOString()
                : ""
            }
            onChange={handleNewTaskChange}
            className="w-12 px-3 py-2 border border-gray-300 rounded-md mb-3"
          />
        </div>

        {isAdmin && (
          <div>
            <p className="font-bold text-md">Assigned Rep</p>
            <select
              name="assignedRep"
              value={newTask.assignedRep}
              onChange={handleNewTaskChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md mb-3"
            >
              <option value="">Select Sales Rep</option>
              {salesReps.map((rep) => (
                <option key={rep} value={rep}>
                  {rep}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={closeNewTaskModal}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Close
        </button>
        <button
          onClick={() => createTask({ ...newTask, taskType: newTask.taskType })}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center gap-x-2"
          disabled={!newTask.title || !newTask.date || (isAdmin && !newTask.assignedRep)}
        >
          Create
        </button>
      </div>
    </div>
  </div>
)}

      <div className={`page ${!showTasks && "hidden"}`}>
        {/* Buttons */}
        <div className="flex gap-x-4 mb-2">
          <Link to={`/leads?leadType=suspect&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-suspect px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-suspect-dark hover: transition duration-300">
              Suspects{" "}
              <span className="font-bold">
                ({getClientTypeCount("Suspect")})
              </span>
            </button>
          </Link>

          <Link to={`/leads?leadType=sleep&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-sleep px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-sleep-dark hover: transition duration-300">
              Sleep{" "}
              <span className="font-bold">({getClientTypeCount("Sleep")})</span>
            </button>
          </Link>

          <Link to={`/leads?leadType=prospect&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-prospect px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-prospect-dark hover: transition duration-300">
              Prospects{" "}
              <span className="font-bold">
                ({getClientTypeCount("Prospect")})
              </span>
            </button>
          </Link>

          <Link to={`/leads?leadType=activation&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-activation px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-activation-dark hover: transition duration-300">
              Activation{" "}
              <span className="font-bold">
                ({getClientTypeCount("Activation")})
              </span>
            </button>
          </Link>

          <Link to={`/leads?leadType=proposal&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-proposal px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-proposal-dark hover: transition duration-300">
              Proposals{" "}
              <span className="font-bold">
                ({getClientTypeCount("Proposal")})
              </span>
            </button>
          </Link>

          <Link to={`/leads?leadType=client&selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-client px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-client-dark hover: transition duration-300">
              Clients{" "}
              <span className="font-bold">
                ({getClientTypeCount("Client")})
              </span>
            </button>
          </Link>

          <Link to={`/leads?selectedSalesRep=${selectedSalesRep}`}>
            <button className="bg-total px-4 py-2 rounded-lg shadow-md transform hover:scale-105 hover:bg-total-dark hover: transition duration-300">
              Total <span className="font-bold">({getClientTypeCount()})</span>
            </button>
          </Link>
        </div>

        {/* <AnalyticsContent selectedSalesRep={selectedSalesRep} /> */}
        <AnalyticsContent2 selectedSalesRep={selectedSalesRep} />

        <div className="flex-col gap-x-4 h-96 mb-8">
          <div className="flex gap-x-4 my-6 items-center">
            <p className="font-bold text-2xl">Tasks</p>
            <button
              onClick={openNewTaskModal}
              className="bg-zinc-700 flex p-2 text-white rounded-md shadow-md gap-x-2 items-center"
            >
              Create New Task <FaPlus />
            </button>
          </div>
          {isAdmin && (
            <div className="mb-4">
              <button
                className={`p-2 mr-2 rounded-lg shadow-md hover:shadow-lg hover:rounded-xl ${
                  selectedSalesRep === "all"
                    ? "bg-blue-500 text-white"
                    : "bg-zinc-100 "
                }`}
                onClick={() => setSelectedSalesRep("all")}
              >
                All
              </button>
              {salesReps.map((rep) => (
                <button
                  key={rep}
                  className={`p-2 mr-2 rounded-lg shadow-md hover:shadow-lg hover:rounded-xl ${
                    selectedSalesRep === rep
                      ? "bg-blue-500 text-white"
                      : "bg-zinc-100 "
                  }`}
                  onClick={() => setSelectedSalesRep(rep)}
                >
                  {rep}
                </button>
              ))}
            </div>
          )}

          <div className="flex gap-x-2 items-center mb-2">
            <button
              onClick={goToPreviousWeek}
              className={`flex gap-x-2 items-center p-2 rounded-md shadow-md text-white ${isThisWeek ? "bg-zinc-300" : "bg-zinc-700"}`}
              disabled={isThisWeek}
            >
              <FaChevronLeft />
              Previous
            </button>

            <p>
              {formattedStartDate} - {formattedEndDate}
            </p>
            <button
              onClick={goToNextWeek}
              className="flex gap-x-2 items-center p-2 rounded-md shadow-md bg-zinc-700 text-white"
            >
              Next <FaChevronRight />
            </button>
          </div>

          <div className="flex gap-x-2">
            {days.map((dayObj) => (
              <div
                key={dayObj.day}
                className={`flex-col w-96 border-4 p-2 border-solid rounded-md ${new Date().toLocaleDateString() === dayObj.date && ` border-blue-500`}`}
              >
                <div className="flex justify-center">
                  {/* Display the number of tasks for the day excluding completed tasks, adjusting wording based on count */}
                </div>
                <div className="flex-col gap-x-2">
                  <div className="flex justify-between items-center">
                    <p
                      className={`font-bold text-xl ${today.toLocaleDateString() === dayObj.date ? "underline" : "text-zinc-900"}`}
                    >
                      {dayObj.day}{" "}
                    </p>
                    <p>{dayObj.date}</p>

                    {dayObj.day !== "Overdue" && (
                      <button
                        className="flex transform hover:scale-105 hover:border-blue-500 hover:text-blue-500 transition duration-300"
                        onClick={() => openPrintModal(dayObj)}
                      >
                        <FaPrint />
                      </button>
                    )}
                  </div>
                  <div className="flex items-end">
                    {dayObj.day === "Overdue" ? (
                      <div>
                        {(() => {
                          const count = overdueTasks.filter(
                            (task) =>
                              (selectedSalesRep === "all" ||
                                task.assignedRep === selectedSalesRep) &&
                              !task.completed,
                          ).length;
                          const initialCallCount = overdueTasks.filter(
                            (task) =>
                              task.title === "Make Initial Call" &&
                              (selectedSalesRep === "all" ||
                                task.assignedRep === selectedSalesRep) &&
                              !task.completed,
                          ).length;
                          return (
                            <>
                              <p>
                                {count} Task{count === 1 ? "" : "s"}
                              </p>
                              <p>
                                {initialCallCount} Initial Call
                                {initialCallCount === 1 ? "" : "s"}
                              </p>
                            </>
                          );
                        })()}
                      </div>
                    ) : (
                      <div>
                        {(() => {
                          const count = tasks.filter((task) => {
                            const taskDate = new Date(task.date);
                            return (
                              isSameDay(taskDate, new Date(dayObj.date)) &&
                              (selectedSalesRep === "all" ||
                                task.assignedRep === selectedSalesRep) &&
                              !task.completed
                            ); // Exclude tasks marked as completed
                          }).length;
                          const initialCallCount = tasks.filter(
                            (task) =>
                              task.title === "Make Initial Call" &&
                              isSameDay(
                                new Date(task.date),
                                new Date(dayObj.date),
                              ) &&
                              (selectedSalesRep === "all" ||
                                task.assignedRep === selectedSalesRep) &&
                              !task.completed,
                          ).length;
                          return (
                            <>
                              <p>
                                {count} Task{count === 1 ? "" : "s"}
                              </p>
                              <p>
                                {initialCallCount} Initial Call
                                {initialCallCount === 1 ? "" : "s"}
                              </p>
                            </>
                          );
                        })()}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className='flex justify-center'>
                  <p className='text-xl text-zinc-900 font-light'>{dayObj.date}</p>
                </div> */}
                {dayObj.day === "Overdue"
                  ? // Render tasks for the "Overdue" day
                    overdueTasks.map((task) => (
                      <>
                        {(task.assignedRep === selectedSalesRep ||
                          selectedSalesRep === "all") && (
                          <TaskComponent
                            key={task._id}
                            task={task}
                            loggedInUser={loggedInUser}
                            toggleDescription={toggleDescription}
                            isDescriptionExpanded={descriptionStates[task._id]}
                            isAdmin={isAdmin}
                            refreshTasks={refreshTasks}
                          />
                        )}
                      </>
                    ))
                  : // Render tasks for other days
                    tasks.map((task) => {
                      const taskDate = new Date(task.date);
                      const isDescriptionExpanded = descriptionStates[task._id];

                      // Check if the task matches the selected sales rep and the date
                      if (
                        (task.assignedRep === selectedSalesRep ||
                          selectedSalesRep === "all") &&
                        taskDate.toDateString() ===
                          new Date(dayObj.date).toDateString() // Compare dates without time
                      ) {
                        return (
                          <TaskComponent
                            key={task._id}
                            task={task}
                            loggedInUser={loggedInUser}
                            toggleDescription={toggleDescription}
                            isDescriptionExpanded={isDescriptionExpanded}
                            color={getColorClass(task.clientType)}
                          />
                        );
                      }

                      return null;
                    })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
